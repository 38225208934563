import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { IReducer } from 'src/interface'
import { dispatchGetCartAfterLogin } from 'src/utils/cart'

const useCartState = () => {
  const [isMFCartLoaded, setIsMFCartLoaded] = useState(false)
  const [cookies] = useCookies()
  const accountReducer = useSelector((state: IReducer) => state.accountReducer)
  const stripeAccountReducer = useSelector((state: IReducer) => state.stripeAccountReducer)

  const refreshCart = () => {
    if (stripeAccountReducer?.stripeInfo?.id) {
      dispatchGetCartAfterLogin(accountReducer.userInfo.BillToAccountObj, stripeAccountReducer.stripeInfo.id)
    } else if (cookies.temporary_user_id) {
      window.dispatchEvent(new CustomEvent('getCartWithoutLogin'))
    }
  }

  const handleIsMFCartLoaded = () => {
    setIsMFCartLoaded(true)
  }

  // Load the cart when the user refreshes the page
  useEffect(() => {
    if (isMFCartLoaded && !accountReducer.loggingin) {
      refreshCart()
    }
  }, [isMFCartLoaded, accountReducer.loggingin])

  return handleIsMFCartLoaded
}

export default useCartState
